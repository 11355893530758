<template>
  <div>
    <!-- SECTION modal-submit-draft -->
    <b-modal
      id="modal-submit-draft"
      :title="$t('reservation.submitBooking')"
      title-class="font-medium-4 font-weight-bolder"
      centered
      size="md"
      @show="showHandle"
    >
      <template #modal-footer="{ cancel }">
        <div class="d-flex justify-content-end w-100">
          <b-button
            size="md"
            variant="outline-secondary"
            class="center rounded-pill mr-2"
            @click="cancel()"
          >
            {{ $t('reservation.back') }}
          </b-button>

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="btn-gradient border-right-0"
            pill
            @click="handleSubmit"
          >
            <div class="d-flex-center px-25">
              Xác nhận
            </div>
          </b-button>
        </div>
      </template>

      <div>
        Bạn có muốn đặt chỗ cùng vé khứ hồi
        <code>{{ bookingData?.roundtripBooking?.bookingCode }}</code>
      </div>
      <b-form-group class="my-1">
        <b-form-radio-group
          id="includeRTB-radios"
          v-model="dataAdd.includeRTB"
          :options="submitOptions"
          name="radios-stacked"
          stacked
        />
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BButton, BFormGroup, BFormRadioGroup, BModal,
} from 'bootstrap-vue'
import cloneDeep from 'lodash/cloneDeep'

import { convertISODateTime, formatCurrency } from '@/@core/utils/filter'

import useReservationHandle from '@reservation/useReservationHandle'

export default {
  components: {
    BModal,
    BFormGroup,
    BButton,
    BFormRadioGroup,
  },
  props: {
    bookingData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { submitBooking } = useReservationHandle()

    const blankData = {
      includeRTB: true,
    }

    const submitOptions = [
      {
        text: 'Có',
        value: true,
      },
      {
        text: 'Không',
        value: false,
      },
    ]

    const dataAdd = ref(cloneDeep(blankData))

    const resetData = () => {
      dataAdd.value = cloneDeep(blankData)
    }

    function showHandle() {
      resetData()
    }

    async function handleSubmit() {
      this.$bvModal.show('modal-api-loading')
      await submitBooking(props.bookingData.id, { includeRTB: dataAdd.value.includeRTB }).catch(() => {})
      emit('refetch-booking-data')
      this.$bvModal.hide('modal-submit-draft')
      this.$bvModal.hide('modal-api-loading')
    }

    return {
      dataAdd,
      showHandle,
      formatCurrency,
      handleSubmit,
      convertISODateTime,
      submitOptions,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
