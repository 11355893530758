<template>
  <b-modal
    id="id-modal-reservation-send-email"
    :title="$t('reservation.sendEmail.title')"
    title-class="text-airline font-medium-4 font-weight-bolder"
    body-class="p-1"
    centered
    no-close-on-backdrop
    size="lg"
    @show="showModal"
  >
    <template #modal-footer="{close}">
      <b-button
        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
        variant="outline-danger"
        pill
        @click="close()"
      >
        {{ $t('reservation.back') }}
      </b-button>

      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        class="btn-gradient px-1 px-md-2"
        pill
        @click="handleSendEmail()"
      >
        {{ $t('reservation.historyActions.SEND_EMAIL') }}
      </b-button>
    </template>

    <div class="mb-1">
      {{ $t('reservation.sendEmail.chooseLanguage') }}
    </div>

    <v-select
      v-model="language"
      :clearable="false"
      :reduce="l => l.value"
      label="key"
      :options="languageOptions"
    >
      <template #option="data">
        <span>
          {{ $te(`reservation.languageCode.${data.key}`) ? $t(`reservation.languageCode.${data.key}`) : data.key }}
        </span>
      </template>

      <template #selected-option="data">
        <span>
          {{ $te(`reservation.languageCode.${data.key}`) ? $t(`reservation.languageCode.${data.key}`) : data.key }}
        </span>
      </template>

      <template #no-options>
        {{ $t('noOptions') }}
      </template>
    </v-select>

    <div
      v-if="getBookingData && ['VN1A', 'VN1A_MT'].includes(getBookingData.source)"
      class="mt-1"
    >
      <div
        v-if="['PAID'].includes(getBookingData.status)"
        class="d-flex mb-50"
      >
        <div class="text-nowrap">
          Gửi kèm số vé
        </div>
        <BFormCheckbox
          v-model="isGetETicket"
          class="ml-75"
          name="custom-email"
          switch
        />
      </div>
      <div
        v-if="isGetETicket"
        class="text-warning mb-1"
      >
        Lưu ý: Gửi kèm số vé sẽ gửi về mail mặc định, không thể thay đổi
      </div>

      <div class="d-flex">
        <div class="text-nowrap">
          Nhập email (tuỳ chọn)
        </div>
        <BFormCheckbox
          v-model="isCustomEmail"
          class="ml-75"
          name="custom-email"
          switch
        />
      </div>

      <BFormInput
        v-if="isCustomEmail"
        v-model="email"
        class="mt-75"
        placeholder="Nhập vào email"
      />
    </div>
    <div
      v-if="getBookingData && ['VJ'].includes(getBookingData.source)"
      class="mt-1"
    >
      <div class="d-flex">
        <div class="text-nowrap">
          Ẩn giá vé khi gửi email (Vietjet Air)
        </div>
        <BFormCheckbox
          v-model="isHiddenFare"
          class="ml-75"
          name="isHiddenFare"
          switch
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import {
  BModal,
  BButton,
  BFormCheckbox,
  BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import { languageCode } from '@/constants/reservation'
import {
  EMAIL_REGEX,
} from '@/constants/selectOptions'

import useReservationHandle from '@reservation/useReservationHandle'

import useToast from '@useToast'

export default {
  components: {
    BModal,
    BButton,
    BFormCheckbox,
    BFormInput,

    vSelect,
  },
  setup() {
    const { toastWarning } = useToast()

    const {
      getBookingData,
      sendEmail,
    } = useReservationHandle()

    const language = ref('vi')
    const languageOptions = ref()

    const isGetETicket = ref(false)
    const isCustomEmail = ref(false)
    const isHiddenFare = ref(false)

    const email = ref(null)

    watch(() => isCustomEmail.value, val => {
      if (!val) {
        email.value = null
      }
      if (val) {
        isGetETicket.value = false
      }
    })
    watch(() => isGetETicket.value, val => {
      if (val) {
        isCustomEmail.value = false
        email.value = null
      }
    })

    function showModal() {
      isCustomEmail.value = false
      isGetETicket.value = false
      email.value = null

      // Default = current locale
      language.value = this.$i18n.locale || 'vi'

      if (getBookingData.value.source.includes('VN1A')) {
        languageOptions.value = languageCode
      } else {
        languageOptions.value = languageCode.filter(item => ['vi', 'en'].includes(item.value))
      }
    }

    function handleSendEmail() {
      if (isCustomEmail.value) {
        if (!email.value || !EMAIL_REGEX.test(email.value)) {
          toastWarning({
            title: 'messagesList.success',
            content: 'Vui lòng kiểm tra và nhập đúng định dạng email !',
          })
          return
        }
      }

      const dataSendEmail = {
        language: language.value,
        email: email.value,
        isHiddenFare: isHiddenFare.value,
        isGetETicket: isGetETicket.value,
      }

      const bookingData = {
        contact: getBookingData.value?.contact,
        paxLists: getBookingData.value?.paxLists,
        source: getBookingData.value?.source,
        bookingCode: getBookingData.value?.bookingCode,
        airlines: getBookingData.value?.airlines,
      }

      this.$bvModal.show('modal-api-loading')
      sendEmail(bookingData, dataSendEmail)
        .then(() => {
          this.$bvModal.hide('id-modal-reservation-send-email')
        })
        .finally(() => {
          this.$bvModal.hide('modal-api-loading')
        })
    }

    return {
      language,
      getBookingData,
      isHiddenFare,
      showModal,
      handleSendEmail,
      languageOptions,
      isCustomEmail,
      email,
      isGetETicket,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
