var render = function () {
  var _vm$eTicketData2, _vm$eTicketData3, _vm$eTicketData4, _vm$eTicketData4$tick, _vm$eTicketData5, _vm$eTicketData5$tick, _vm$eTicketData6, _vm$eTicketData6$pass, _vm$eTicketData7, _vm$eTicketData7$tick, _vm$eTicketData8, _vm$eTicketData8$pass, _vm$eTicketData9, _vm$eTicketData9$tick, _vm$eTicketData10, _vm$eTicketData10$pas, _vm$eTicketData11, _vm$eTicketData11$tic, _vm$eTicketData11$tic2, _vm$eTicketData12, _vm$eTicketData12$res, _vm$eTicketData13, _vm$eTicketData14, _vm$eTicketData14$res, _vm$eTicketData15, _vm$eTicketData16, _vm$eTicketData16$tic, _vm$eTicketData17, _vm$eTicketData17$tic, _vm$eTicketData18, _vm$eTicketData18$tic, _vm$eTicketData19, _vm$eTicketData19$tic, _vm$eTicketData20, _vm$eTicketData20$tic, _vm$eTicketData20$tic2, _vm$eTicketData21, _vm$eTicketData21$tic, _vm$eTicketData21$tic2, _vm$eTicketData22, _vm$eTicketData22$tic, _vm$eTicketData22$tic2, _vm$eTicketData23, _vm$eTicketData24, _vm$eTicketData24$tic, _vm$eTicketData25, _vm$eTicketData25$tic, _vm$eTicketData26, _vm$eTicketData26$tic;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-modal', {
    attrs: {
      "id": "modal-modify-view-eticket-details",
      "centered": "",
      "ok-variant": "primary",
      "no-close-on-backdrop": "",
      "footer-class": "d-flex-center py-50",
      "header-bg-variant": "light-info",
      "header-class": "py-25 pr-0",
      "size": "xl"
    },
    on: {
      "show": _vm.handleOpenModal,
      "hide": _vm.handleHideModal
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('b-row', {
          staticClass: "justify-content-between align-items-center w-100 mx-0"
        }, [_c('h4', {
          staticClass: "text-airline font-medium-4 font-weight-bolder m-50"
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.eticketInfo')) + " ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "mr-50",
          attrs: {
            "variant": "flat-secondary"
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_c('feather-icon', {
          staticClass: "text-dark",
          attrs: {
            "icon": "XIcon"
          }
        })], 1)], 1)];
      }
    }, {
      key: "modal-footer",
      fn: function fn(_ref2) {
        var _vm$eTicketData;
        var close = _ref2.close;
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "center rounded-pill px-2",
          attrs: {
            "size": "md",
            "variant": "outline-secondary"
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.back')) + " ")]), !_vm.isReadonly && _vm.eTicketData && _vm.eTicketData.platingCarrier !== 'QH' && !['VN1A', 'VN1A_MT'].includes((_vm$eTicketData = _vm.eTicketData) === null || _vm$eTicketData === void 0 ? void 0 : _vm$eTicketData.source) ? _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-gradient mr-1",
          attrs: {
            "pill": "",
            "disabled": !_vm.isOutTicket || _vm.isBookingOutSystem
          },
          on: {
            "click": _vm.addEticketToBooking
          }
        }, [_c('div', {
          staticClass: "d-flex-center px-25"
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "PlusCircleIcon"
          }
        }), _vm._v(" " + _vm._s(_vm.$t('reservation.addTicketToBooking')) + " ")], 1)]) : _vm._e()];
      }
    }])
  }, [_c('b-overlay', {
    staticStyle: {
      "min-height": "200px"
    },
    attrs: {
      "show": _vm.loading,
      "rounded": "sm",
      "no-fade": "",
      "variant": "white",
      "spinner-variant": "info",
      "opacity": "0.85"
    }
  }, [(_vm$eTicketData2 = _vm.eTicketData) !== null && _vm$eTicketData2 !== void 0 && _vm$eTicketData2.ticketText && ['', 'QH'].includes((_vm$eTicketData3 = _vm.eTicketData) === null || _vm$eTicketData3 === void 0 ? void 0 : _vm$eTicketData3.platingCarrier) ? _c('div', [_c('ActionsHistory', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: true,
      expression: "true"
    }],
    attrs: {
      "history-data": _vm.eTicketData.ticketText
    }
  })], 1) : _vm.eTicketData ? _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex-center font-weight-bolder text-dark"
  }, [_c('div', {
    staticClass: "mr-2"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.eTicket')) + ": "), _c('span', {
    staticClass: "text-airline"
  }, [_vm._v(_vm._s(((_vm$eTicketData4 = _vm.eTicketData) === null || _vm$eTicketData4 === void 0 ? void 0 : (_vm$eTicketData4$tick = _vm$eTicketData4.ticket) === null || _vm$eTicketData4$tick === void 0 ? void 0 : _vm$eTicketData4$tick.number) || ((_vm$eTicketData5 = _vm.eTicketData) === null || _vm$eTicketData5 === void 0 ? void 0 : (_vm$eTicketData5$tick = _vm$eTicketData5.ticket) === null || _vm$eTicketData5$tick === void 0 ? void 0 : _vm$eTicketData5$tick.number)))])]), _c('div', [_vm._v(" " + _vm._s(_vm.$t('invoice.customerName')) + ": "), _c('span', {
    staticClass: "text-airline"
  }, [_vm._v(_vm._s("".concat(((_vm$eTicketData6 = _vm.eTicketData) === null || _vm$eTicketData6 === void 0 ? void 0 : (_vm$eTicketData6$pass = _vm$eTicketData6.passenger) === null || _vm$eTicketData6$pass === void 0 ? void 0 : _vm$eTicketData6$pass.lastName) || ((_vm$eTicketData7 = _vm.eTicketData) === null || _vm$eTicketData7 === void 0 ? void 0 : (_vm$eTicketData7$tick = _vm$eTicketData7.ticket) === null || _vm$eTicketData7$tick === void 0 ? void 0 : _vm$eTicketData7$tick.lastName), "/ ").concat(((_vm$eTicketData8 = _vm.eTicketData) === null || _vm$eTicketData8 === void 0 ? void 0 : (_vm$eTicketData8$pass = _vm$eTicketData8.passenger) === null || _vm$eTicketData8$pass === void 0 ? void 0 : _vm$eTicketData8$pass.firstName) || ((_vm$eTicketData9 = _vm.eTicketData) === null || _vm$eTicketData9 === void 0 ? void 0 : (_vm$eTicketData9$tick = _vm$eTicketData9.ticket) === null || _vm$eTicketData9$tick === void 0 ? void 0 : _vm$eTicketData9$tick.firstName), " ").concat(((_vm$eTicketData10 = _vm.eTicketData) === null || _vm$eTicketData10 === void 0 ? void 0 : (_vm$eTicketData10$pas = _vm$eTicketData10.passenger) === null || _vm$eTicketData10$pas === void 0 ? void 0 : _vm$eTicketData10$pas.title) || '')))])])]), _c('div', {
    staticClass: "d-flex-center font-weight-bolder text-dark my-2"
  }, [(_vm$eTicketData11 = _vm.eTicketData) !== null && _vm$eTicketData11 !== void 0 && (_vm$eTicketData11$tic = _vm$eTicketData11.ticket) !== null && _vm$eTicketData11$tic !== void 0 && (_vm$eTicketData11$tic2 = _vm$eTicketData11$tic.coupons) !== null && _vm$eTicketData11$tic2 !== void 0 && _vm$eTicketData11$tic2.length ? _c('div', {
    staticClass: "mr-1 mr-xl-4"
  }, [_vm._v(" Number of Coupons: "), _c('span', {
    staticClass: "text-airline"
  }, [_vm._v(_vm._s(_vm.eTicketData.ticket.coupons.length))])]) : _vm._e(), (_vm$eTicketData12 = _vm.eTicketData) !== null && _vm$eTicketData12 !== void 0 && (_vm$eTicketData12$res = _vm$eTicketData12.reservation) !== null && _vm$eTicketData12$res !== void 0 && _vm$eTicketData12$res.createdDate ? _c('div', {
    staticClass: "mr-1 mr-xl-4"
  }, [_vm._v(" Created: "), _c('span', {
    staticClass: "text-airline"
  }, [_vm._v(_vm._s(_vm.convertISODateTime(_vm.eTicketData.reservation.createdDate).date))])]) : _vm._e(), (_vm$eTicketData13 = _vm.eTicketData) !== null && _vm$eTicketData13 !== void 0 && _vm$eTicketData13.issuedDate ? _c('div', {
    staticClass: "mr-1 mr-xl-4"
  }, [_vm._v(" Isued: "), _c('span', {
    staticClass: "text-airline"
  }, [_vm._v(_vm._s(_vm.convertISODateTime(_vm.eTicketData.issuedDate).date))])]) : _vm._e(), _c('div', {
    staticClass: "mr-1 mr-xl-4"
  }, [_vm._v(" PNR: "), _c('span', {
    staticClass: "text-airline"
  }, [_vm._v(_vm._s(((_vm$eTicketData14 = _vm.eTicketData) === null || _vm$eTicketData14 === void 0 ? void 0 : (_vm$eTicketData14$res = _vm$eTicketData14.reservation) === null || _vm$eTicketData14$res === void 0 ? void 0 : _vm$eTicketData14$res.pnrNumber) || ((_vm$eTicketData15 = _vm.eTicketData) === null || _vm$eTicketData15 === void 0 ? void 0 : _vm$eTicketData15.pnr)))])]), _c('div', {
    staticClass: "mr-1 mr-xl-4"
  }, [_vm._v(" Type: "), _c('span', {
    staticClass: "text-airline"
  }, [_vm._v(_vm._s(((_vm$eTicketData16 = _vm.eTicketData) === null || _vm$eTicketData16 === void 0 ? void 0 : (_vm$eTicketData16$tic = _vm$eTicketData16.ticket) === null || _vm$eTicketData16$tic === void 0 ? void 0 : _vm$eTicketData16$tic.type) || ((_vm$eTicketData17 = _vm.eTicketData) === null || _vm$eTicketData17 === void 0 ? void 0 : (_vm$eTicketData17$tic = _vm$eTicketData17.ticket) === null || _vm$eTicketData17$tic === void 0 ? void 0 : _vm$eTicketData17$tic.ticketType)))])]), (_vm$eTicketData18 = _vm.eTicketData) !== null && _vm$eTicketData18 !== void 0 && (_vm$eTicketData18$tic = _vm$eTicketData18.ticket) !== null && _vm$eTicketData18$tic !== void 0 && _vm$eTicketData18$tic.status ? _c('div', [_vm._v(" Status: "), _c('span', {
    staticClass: "text-airline"
  }, [_vm._v(_vm._s((_vm$eTicketData19 = _vm.eTicketData) === null || _vm$eTicketData19 === void 0 ? void 0 : (_vm$eTicketData19$tic = _vm$eTicketData19.ticket) === null || _vm$eTicketData19$tic === void 0 ? void 0 : _vm$eTicketData19$tic.status))])]) : _vm._e()])]), (_vm$eTicketData20 = _vm.eTicketData) !== null && _vm$eTicketData20 !== void 0 && (_vm$eTicketData20$tic = _vm$eTicketData20.ticket) !== null && _vm$eTicketData20$tic !== void 0 && (_vm$eTicketData20$tic2 = _vm$eTicketData20$tic.coupons) !== null && _vm$eTicketData20$tic2 !== void 0 && _vm$eTicketData20$tic2.length ? _c('b-col', {
    staticClass: "my-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-table', {
    staticClass: "position-relative",
    staticStyle: {
      "max-height": "45vh"
    },
    attrs: {
      "sticky-header": "",
      "responsive": "",
      "show-empty": "",
      "bordered": "",
      "primary-key": "id",
      "thead-class": "text-center",
      "tbody-class": "text-center",
      "items": _vm.eTicketData.ticket.coupons,
      "fields": _vm.eTicketTableColumn,
      "empty-text": _vm.$t('noRecordFund')
    },
    scopedSlots: _vm._u([_vm._l(_vm.eTicketTableColumn, function (column, index) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(data) {
          return [_c('div', {
            key: index,
            staticClass: "text-dark"
          }, [_c('span', {
            staticClass: "text-nowrap"
          }, [_vm._v(" " + _vm._s(data.label) + " ")])])];
        }
      };
    }), {
      key: "cell(NBR)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.couponNumber) + " ")];
      }
    }, {
      key: "cell(CX)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item.marriageGroup) + " ")];
      }
    }, {
      key: "cell(AL)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(item.airline) + " ")];
      }
    }, {
      key: "cell(FLT)",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_vm._v(" " + _vm._s(item.flightNumber) + " ")];
      }
    }, {
      key: "cell(CL)",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_vm._v(" " + _vm._s(item.bookingClass) + " ")];
      }
    }, {
      key: "cell(DEP)",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('div', {}, [_vm._v(" " + _vm._s(_vm.convertISODateTime(item.departure.at, item.departure.timezone).dayMonth) + " ")])];
      }
    }, {
      key: "cell(FROM)",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_vm._v(" " + _vm._s(item.departure.code) + " ")];
      }
    }, {
      key: "cell(TO)",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_vm._v(" " + _vm._s(item.arrival.code) + " ")];
      }
    }, {
      key: "cell(TIME)",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_vm._v(" " + _vm._s(_vm.convertISODateTime(item.departure.at, item.departure.timezone).time) + " ")];
      }
    }, {
      key: "cell(bk_st)",
      fn: function fn(_ref12) {
        var item = _ref12.item;
        return [_vm._v(" " + _vm._s(item.segmentStatus) + " ")];
      }
    }, {
      key: "cell(fare_basic)",
      fn: function fn(_ref13) {
        var item = _ref13.item;
        return [_vm._v(" " + _vm._s(item.fareBasisCode) + " ")];
      }
    }, {
      key: "cell(status)",
      fn: function fn(_ref14) {
        var item = _ref14.item;
        return [_vm._v(" " + _vm._s(item.status) + " ")];
      }
    }, {
      key: "cell(freq_flyer)",
      fn: function fn() {
        return [_c('span', {}, [_vm._v("...")])];
      },
      proxy: true
    }, {
      key: "cell(bags)",
      fn: function fn(_ref15) {
        var bagAllowance = _ref15.item.bagAllowance;
        return [_vm._v(" " + _vm._s(bagAllowance || 'NILL') + " ")];
      }
    }], null, true)
  })], 1) : (_vm$eTicketData21 = _vm.eTicketData) !== null && _vm$eTicketData21 !== void 0 && (_vm$eTicketData21$tic = _vm$eTicketData21.ticket) !== null && _vm$eTicketData21$tic !== void 0 && (_vm$eTicketData21$tic2 = _vm$eTicketData21$tic.segments) !== null && _vm$eTicketData21$tic2 !== void 0 && _vm$eTicketData21$tic2.length ? _c('b-col', {
    staticClass: "my-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-table', {
    staticClass: "position-relative",
    staticStyle: {
      "max-height": "45vh"
    },
    attrs: {
      "sticky-header": "",
      "responsive": "",
      "show-empty": "",
      "bordered": "",
      "primary-key": "id",
      "thead-class": "text-center",
      "tbody-class": "text-center",
      "items": _vm.eTicketData.ticket.segments,
      "fields": _vm.eTicketVN1ATableColumn,
      "empty-text": _vm.$t('noRecordFund')
    },
    scopedSlots: _vm._u([_vm._l(_vm.eTicketTableColumn, function (column, index) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(data) {
          return [_c('div', {
            key: index,
            staticClass: "text-dark"
          }, [_c('span', {
            staticClass: "text-nowrap"
          }, [_vm._v(" " + _vm._s(data.label) + " ")])])];
        }
      };
    }), {
      key: "cell(AL)",
      fn: function fn(_ref16) {
        var item = _ref16.item;
        return [_vm._v(" " + _vm._s(item.airline) + " ")];
      }
    }, {
      key: "cell(FLT)",
      fn: function fn(_ref17) {
        var item = _ref17.item;
        return [_vm._v(" " + _vm._s(item.flightNumber) + " ")];
      }
    }, {
      key: "cell(CL)",
      fn: function fn(_ref18) {
        var item = _ref18.item;
        return [_vm._v(" " + _vm._s(item.bookingClass) + " ")];
      }
    }, {
      key: "cell(DEP)",
      fn: function fn(_ref19) {
        var item = _ref19.item;
        return [_vm._v(" " + _vm._s(_vm.convertISODateTime(item.departureDate).dayMonth) + " ")];
      }
    }, {
      key: "cell(FROM)",
      fn: function fn(_ref20) {
        var item = _ref20.item;
        return [_vm._v(" " + _vm._s(item.departure) + " ")];
      }
    }, {
      key: "cell(TO)",
      fn: function fn(_ref21) {
        var item = _ref21.item;
        return [_vm._v(" " + _vm._s(item.arrival) + " ")];
      }
    }, {
      key: "cell(TIME)",
      fn: function fn(_ref22) {
        var item = _ref22.item;
        return [_vm._v(" " + _vm._s(_vm.convertISODateTime(item.departureDate).time) + " ")];
      }
    }, {
      key: "cell(fare_basic)",
      fn: function fn(_ref23) {
        var item = _ref23.item;
        return [_vm._v(" " + _vm._s(item.fareBasisCode) + " ")];
      }
    }, {
      key: "cell(status)",
      fn: function fn(_ref24) {
        var item = _ref24.item;
        return [_vm._v(" " + _vm._s(item.status) + " ")];
      }
    }, {
      key: "cell(freq_flyer)",
      fn: function fn() {
        return [_c('span', {}, [_vm._v("...")])];
      },
      proxy: true
    }, {
      key: "cell(bags)",
      fn: function fn(_ref25) {
        var baggageAllowance = _ref25.item.baggageAllowance;
        return [_vm._v(" " + _vm._s(baggageAllowance || 'NILL') + " ")];
      }
    }], null, true)
  })], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "text-dark font-weight-bolder"
  }, [_vm.eTicketData.fareCalc ? _c('div', [_vm._v(" Fare Calc: "), _c('p', {
    staticClass: "text-airline"
  }, [_vm._v(" " + _vm._s(_vm.eTicketData.fareCalc) + " ")])]) : _vm._e(), (_vm$eTicketData22 = _vm.eTicketData) !== null && _vm$eTicketData22 !== void 0 && (_vm$eTicketData22$tic = _vm$eTicketData22.ticket) !== null && _vm$eTicketData22$tic !== void 0 && (_vm$eTicketData22$tic2 = _vm$eTicketData22$tic.remarks) !== null && _vm$eTicketData22$tic2 !== void 0 && _vm$eTicketData22$tic2.length ? _c('div', [_vm._v(" Endorsement: "), _vm._l(_vm.eTicketData.ticket.remarks, function (remarkItem, remarkIndex) {
    return _c('p', {
      key: remarkIndex,
      staticClass: "text-airline"
    }, [_vm._v(" " + _vm._s(remarkItem.endorsement.text || '') + " ")]);
  })], 2) : _vm._e(), _vm.eTicketData.formOfPayment ? _c('div', [_vm._v(" Form of Payment: "), _c('p', {
    staticClass: "text-airline"
  }, [_vm._v(" " + _vm._s(_vm.eTicketData.formOfPayment) + " ")])]) : _vm._e(), _vm.eTicketData.issuedBy ? _c('div', [_vm._v(" Isued: "), _c('p', {
    staticClass: "text-airline"
  }, [_vm._v(" " + _vm._s(_vm.eTicketData.issuedBy) + " "), _vm.eTicketData.agent && _vm.eTicketData.agent.agentID ? _c('span', [_vm._v(_vm._s(_vm.eTicketData.agent.agentID || ''))]) : _vm._e()])]) : _vm._e(), _c('div', {
    class: "\n              ".concat(['VN1A', 'VN1A_MT'].includes((_vm$eTicketData23 = _vm.eTicketData) === null || _vm$eTicketData23 === void 0 ? void 0 : _vm$eTicketData23.source) ? 'd-flex-center' : 'd-flex', "\n              ")
  }, [_c('div', {
    staticClass: "mr-xl-5 mr-2"
  }, [_vm._v(" Fare: "), _c('span', {
    staticClass: "text-airline"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.eTicketData.basePrice || ((_vm$eTicketData24 = _vm.eTicketData) === null || _vm$eTicketData24 === void 0 ? void 0 : (_vm$eTicketData24$tic = _vm$eTicketData24.ticket) === null || _vm$eTicketData24$tic === void 0 ? void 0 : _vm$eTicketData24$tic.netPrice))) + " ")])]), _c('div', {
    staticClass: "mr-xl-5 mr-2"
  }, [_vm._v(" Tax: "), _c('span', {
    staticClass: "text-airline"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.eTicketData.totalTax || ((_vm$eTicketData25 = _vm.eTicketData) === null || _vm$eTicketData25 === void 0 ? void 0 : (_vm$eTicketData25$tic = _vm$eTicketData25.ticket) === null || _vm$eTicketData25$tic === void 0 ? void 0 : _vm$eTicketData25$tic.tax))) + " ")])]), _c('div', {
    staticClass: "mr-xl-5 mr-2"
  }, [_vm._v(" Total: "), _c('span', {
    staticClass: "text-airline"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.eTicketData.totalPrice || ((_vm$eTicketData26 = _vm.eTicketData) === null || _vm$eTicketData26 === void 0 ? void 0 : (_vm$eTicketData26$tic = _vm$eTicketData26.ticket) === null || _vm$eTicketData26$tic === void 0 ? void 0 : _vm$eTicketData26$tic.total))) + " ")])])])])]), _c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "cols": "12"
    }
  }, [_vm.eTicketData.history ? _c('b-card', {
    attrs: {
      "header-bg-variant": "light-info py-75 mb-0",
      "no-body": ""
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex justify-content-between w-100"
        }, [_c('h6', {
          staticClass: "text-airline font-medium-3 m-0"
        }, [_vm._v(" " + _vm._s(_vm.$t("reservation.history.title")) + " ")]), _c('div', {
          staticClass: "cursor-pointer",
          class: {
            'rotate-180': _vm.showActionsHistory
          },
          on: {
            "click": function click($event) {
              _vm.showActionsHistory = !_vm.showActionsHistory;
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "ChevronDownIcon",
            "size": "24"
          }
        })], 1)])];
      },
      proxy: true
    }], null, false, 4131551572)
  }, [_c('ActionsHistory', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showActionsHistory,
      expression: "showActionsHistory"
    }],
    attrs: {
      "id": "eTicketData",
      "history-data": _vm.eTicketData.history
    }
  })], 1) : _vm._e()], 1)], 1) : _c('b-row', [_c('b-alert', {
    attrs: {
      "variant": "danger"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.errGetEticket')) + " ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }