import cloneDeep from 'lodash/cloneDeep'

const defaultState = () => ({
  selectedTrips: [],
})

export default {
  namespaced: true,

  state: {
    ...defaultState(),
  },

  getters: {
    getSelectedTrips: state => state.selectedTrips,
  },

  mutations: {
    RESET_STORE(state) {
      Object.assign(state, defaultState())
    },
    SET_SELECTED_TRIP(state, { data, index }) {
      const cloneData = cloneDeep(state.selectedTrips)
      cloneData[index] = data
      state.selectedTrips = cloneData
    },
    SET_SELECTED_TRIP_ARRAY(state, val) {
      state.selectedTrips = cloneDeep(val)
    },
  },

  actions: {
    resetStore({ commit }) {
      commit('RESET_STORE')
    },

    setSelectedTrip({ commit }, val) {
      commit('SET_SELECTED_TRIP', val)
    },

    setSelectedTripArray({ commit }, val) {
      commit('SET_SELECTED_TRIP_ARRAY', val)
    },
  },
}
