var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('BModal', {
    attrs: {
      "id": "modal-recalculate-price",
      "title": _vm.$t('reservation.recalculatePriceFlight'),
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "centered": "",
      "no-close-on-backdrop": "",
      "size": "lg"
    },
    on: {
      "show": _vm.handleShowModal,
      "hide": _vm.handleHideModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "center rounded-pill width-100",
          attrs: {
            "size": "md",
            "variant": "outline-secondary"
          },
          on: {
            "click": _vm.handleHideModal
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.close')) + " ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-gradient mt-lg-25  border-0",
          attrs: {
            "disabled": !_vm.indexSelectedFlightToRecalculatePrice.length || _vm.isErrorPlatingAirlineRequired,
            "pill": ""
          },
          on: {
            "click": _vm.submitRecalculatePrice
          }
        }, [_c('span', {
          staticClass: "align-middle"
        }, [_vm._v(_vm._s(_vm.$t('reservation.confirm')))])])];
      },
      proxy: true
    }])
  }, [_c('b-card', {
    attrs: {
      "body-class": "py-1"
    }
  }, [_c('div', {
    staticClass: "text-heading-5 mb-25"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.selectItineraryToRecalculatePrice')) + " ")]), _c('b-form-group', {
    staticClass: "mb-0",
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_vm.getBookingData.itineraries.flat().length > 1 ? _c('b-form-checkbox', {
          attrs: {
            "aria-describedby": "itineraries",
            "aria-controls": "itineraries",
            "disabled": _vm.isDisableCheckAllTrip(_vm.getBookingData.itineraries)
          },
          on: {
            "change": _vm.itinerariesToggleAll
          },
          model: {
            value: _vm.itinerariesAllSelected,
            callback: function callback($$v) {
              _vm.itinerariesAllSelected = $$v;
            },
            expression: "itinerariesAllSelected"
          }
        }, [_c('div', {
          staticClass: "text-body-2"
        }, [_vm._v(" " + _vm._s(_vm.itinerariesAllSelected ? _vm.$t('reservation.unselectAll') : _vm.$t('reservation.selectAll')) + " ")])]) : _vm._e()];
      },
      proxy: true
    }, {
      key: "default",
      fn: function fn(_ref) {
        var ariaDescribedby = _ref.ariaDescribedby;
        return [_c('b-form-checkbox-group', {
          staticClass: "mb-1",
          attrs: {
            "aria-describedby": ariaDescribedby,
            "stacked": "",
            "name": "itineraries"
          },
          model: {
            value: _vm.indexSelectedFlightToRecalculatePrice,
            callback: function callback($$v) {
              _vm.indexSelectedFlightToRecalculatePrice = $$v;
            },
            expression: "indexSelectedFlightToRecalculatePrice"
          }
        }, _vm._l(_vm.getBookingData.itineraries, function (itinerary, indexItinerary) {
          return _c('div', {
            key: indexItinerary,
            staticClass: "d-flex flex-column"
          }, _vm._l(itinerary, function (segment, indexSegment) {
            return _c('div', {
              key: indexSegment
            }, [_c('b-form-checkbox', {
              attrs: {
                "value": segment.segmentId,
                "disabled": ['VN1A'].includes(_vm.getBookingData.source) || _vm.isDisabledHLSegment(segment)
              }
            }, [_c('div', {
              staticClass: "d-flex align-items-center"
            }, [_c('div', {
              staticClass: "mr-50 text-heading-4"
            }, [_vm._v(" " + _vm._s(_vm.convertShortTrip(segment)) + " ")])])])], 1);
          }), 0);
        }), 0)];
      }
    }])
  })], 1), _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-table', {
    staticClass: "mb-0",
    attrs: {
      "bordered": "",
      "centered": "",
      "responsive": "",
      "thead-class": "text-dark text-center text-nowrap",
      "tbody-class": "text-dark text-center text-nowrap",
      "fields": _vm.paxListsColumns,
      "items": _vm.paxListsData
    },
    scopedSlots: _vm._u([_vm._l(_vm.paxListsColumns, function (column, index) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(data) {
          return [_c('div', {
            key: index,
            staticClass: "text-dark"
          }, [data.column === 'checkbox' ? _c('span', [_c('b-form-checkbox', {
            staticClass: "mr-1 pl-0",
            attrs: {
              "inline": "",
              "disabled": ['VN1A'].includes(_vm.getBookingData.source)
            },
            on: {
              "change": function change($event) {
                return _vm.handlePaxsSelectAll(_vm.allPaxsSelected);
              }
            },
            model: {
              value: _vm.allPaxsSelected,
              callback: function callback($$v) {
                _vm.allPaxsSelected = $$v;
              },
              expression: "allPaxsSelected"
            }
          })], 1) : _c('span', {
            staticClass: "text-nowrap"
          }, [_vm._v(" " + _vm._s(_vm.$t("reservation.".concat(data.column))) + " ")])])];
        }
      };
    }), {
      key: "cell(checkbox)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('b-form-checkbox', {
          staticClass: "p-0",
          attrs: {
            "name": "check-box",
            "inline": "",
            "disabled": ['VN1A'].includes(_vm.getBookingData.source),
            "checked": _vm.isChecked(item.paxId)
          },
          on: {
            "change": function change($event) {
              return _vm.chooseItem(item.paxId);
            }
          }
        })];
      }
    }, {
      key: "cell(pax_id)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-center text-truncate"
        }, [_vm._v(" " + _vm._s(data.item.paxId.length <= 6 ? data.item.paxId : data.index + 1) + " ")])];
      }
    }, {
      key: "cell(pax_type)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "custom-v-select-pax-type text-center"
        }, [_c('v-select', {
          ref: "vSelectPaxTypeRef",
          attrs: {
            "id": "honorific",
            "append-to-body": true,
            "calculate-position": _vm.withPopper,
            "options": ['ADULT', 'CHILD'],
            "label": "text",
            "disabled": ['VN1A', '1G'].includes(_vm.getBookingData.source) || (['INFANT'].includes(item.paxType) ? true : false),
            "clearable": false
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(data) {
              return [_c('span', {
                staticClass: "text-uppercase"
              }, [_vm._v(" " + _vm._s(_vm.$t("reservation.".concat(data.text))) + " ")])];
            }
          }, {
            key: "selected-option",
            fn: function fn(data) {
              return [_c('span', {
                staticClass: "text-uppercase"
              }, [_vm._v(" " + _vm._s(_vm.$t("reservation.".concat(data.text))) + " ")])];
            }
          }], null, true),
          model: {
            value: item.paxType,
            callback: function callback($$v) {
              _vm.$set(item, "paxType", $$v);
            },
            expression: "item.paxType"
          }
        })], 1)];
      }
    }, {
      key: "cell(full_name)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s("".concat(item.lastName, " / ").concat(item.firstName)) + " ")])];
      }
    }], null, true)
  })], 1), _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-row', {
    staticClass: "mx-0"
  }, [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "platingAirline"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex justify-content-start gap-1"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v("Airline")]), _vm.bookingData.source.includes('1S') || _vm.bookingData.source.includes('1G') ? _c('span', {
          staticClass: "text-danger"
        }, [_vm._v("(*)")]) : _vm._e()])];
      },
      proxy: true
    }])
  }, [_c('b-form-input', {
    attrs: {
      "id": "platingAirline",
      "state": !_vm.isErrorPlatingAirlineRequired ? null : false,
      "disabled": _vm.bookingData.source.includes('1S'),
      "placeholder": _vm.$t('flight.placeholderInput')
    },
    model: {
      value: _vm.platingAirline,
      callback: function callback($$v) {
        _vm.platingAirline = $$v;
      },
      expression: "platingAirline"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "Tour-Code"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v("Tour Code")])];
      },
      proxy: true
    }])
  }, [_c('b-form-input', {
    attrs: {
      "id": "Tour-Code",
      "placeholder": _vm.$t('flight.placeholderInput'),
      "formatter": _vm.upperCaseFormatter
    },
    model: {
      value: _vm.tourCode,
      callback: function callback($$v) {
        _vm.tourCode = $$v;
      },
      expression: "tourCode"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "Account-Code"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v("Account Code")])];
      },
      proxy: true
    }])
  }, [_c('b-form-input', {
    attrs: {
      "id": "Account-Code",
      "formatter": _vm.upperCaseFormatter,
      "placeholder": _vm.$t('flight.placeholderInput')
    },
    model: {
      value: _vm.accountCode,
      callback: function callback($$v) {
        _vm.accountCode = $$v;
      },
      expression: "accountCode"
    }
  })], 1)], 1)], 1)], 1), _vm.getBookingData.source.includes('1S') && _vm.canPriceBargainFinder ? _c('b-card', {
    attrs: {
      "body-class": "py-1"
    }
  }, [_c('div', {
    staticClass: "margin-checkbox-label d-flex-center justify-content-around gap-2 text-center"
  }, [_c('b-form-checkbox', {
    attrs: {
      "name": "retain-checkbox"
    },
    model: {
      value: _vm.isBargainFinder,
      callback: function callback($$v) {
        _vm.isBargainFinder = $$v;
      },
      expression: "isBargainFinder"
    }
  }, [_c('h5', {
    staticClass: "text-dark mb-0"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.bargainFinder')) + " ")])])], 1)]) : _vm._e()], 1), _c('ModalRecalculateShowPrice', {
    attrs: {
      "new-price": _vm.newPrice,
      "get-booking-data": _vm.getBookingData,
      "loading": _vm.showCalculatePriceLoading,
      "conversion-timeout": _vm.conversionTimeout,
      "conversion-id": _vm.conversionId
    },
    on: {
      "save-price": _vm.submitSaveRecalculatePrice,
      "cleanAirPricingConversion": _vm.cleanConversion
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }