<template>
  <div id="result-component">
    <div
      v-if="getLoading"
      class="text-center mt-3"
      style="min-height: 100px"
    >
      <b-spinner variant="info" />
      <p class="text-info">
        Đang tìm kiếm chuyến bay...
      </p>
    </div>

    <div v-else-if="!isEmpty(resultSearchFlight)">
      <HeaderTabFlight />
      <b-tabs
        v-model="tabIndex"
        pills
        nav-class="tab-title"
        nav-wrapper-class="nav-wrapper-class"
      >
        <b-tab
          v-for="(itinerary, indexItinerary) in resultSearchFlight"
          :key="indexItinerary"
        >
          <ItineraryTab
            :itinerary="itinerary"
            :index-itinerary="indexItinerary"
            :is-add-flight="isAddFlight"
            :selected-trip.sync="selectedTrips[indexItinerary]"
          />
        </b-tab>
      </b-tabs>
    </div>

    <div
      v-if="!getLoading && isAddFlight && !isEmpty(resultSearchFlight)"
      class="w-100 d-flex-center my-75"
    >
      <b-button
        variant="outline-info"
        class="px-2 py-50 mr-1 rounded-pill d-flex-center"
        @click="handleRefreshResultSearch"
      >
        <feather-icon
          icon="RefreshCwIcon"
          size="18"
          class="mr-1"
        />
        Làm mới
      </b-button>
    </div>

    <AddFlightFooter
      v-if="isAddFlight"
      :booking-data="bookingData"
      :selected-trips.sync="selectedTrips"
    />

    <ChangeFlightFooter
      v-if="!isAddFlight"
      :booking-data="bookingData"
      :selected-trips.sync="selectedTrips"
    />
  </div>
</template>

<script>
import {
  BTab, BTabs, BButton, BSpinner,
} from 'bootstrap-vue'
import { onMounted, ref } from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'

import store from '@/store'

import { convertISODateTime } from '@core/utils/filter'

import useReservationDetailBookingHandle from '@reservation/reservation-modify/components/detail/flights-details/useReservationDetailBookingHandle'

export default {
  components: {
    BTab,
    BTabs,
    BButton,
    BSpinner,

    HeaderTabFlight: () => import('./components/HeaderTabFlight.vue'),
    ItineraryTab: () => import('./components/ItineraryTab.vue'),
    AddFlightFooter: () => import('../add-flights/result/footer.vue'),
    ChangeFlightFooter: () => import('../change-flights/result/footer.vue'),
  },
  props: {
    bookingData: {
      type: Object,
      required: true,
    },
    isAddFlight: {
      type: Boolean,
      default: () => true,
    },
  },
  computed: {
    tabIndex: {
      get() {
        return this.$store.getters['app-reservation/getTabIndex']
      },
      set(newValue) {
        this.$store.dispatch('app-reservation/setTabIndex', newValue)
      },
    },
  },
  setup() {
    const {
      getLoading,
      tempSelectedTrips,
      resultSearchFlight,
      getSearchFlightArray,
      flightSearch,
    } = useReservationDetailBookingHandle()

    const selectedTrips = ref(
      tempSelectedTrips.value?.length
        ? tempSelectedTrips.value
        : [...getSearchFlightArray.value.map(() => null)],
    )

    onMounted(() => {
      if (!isEmpty(getSearchFlightArray.value)) {
        store.dispatch('app-modify-flight/setSelectedTripArray', [...getSearchFlightArray.value.map(() => null)])
      }
    })

    function handleRefreshResultSearch() {
      window.location.reload()
    }

    return {
      isEmpty,
      getLoading,
      flightSearch,
      selectedTrips,
      tempSelectedTrips,
      convertISODateTime,
      resultSearchFlight,
      getSearchFlightArray,
      handleRefreshResultSearch,
    }
  },
}
</script>

<style lang="scss" scoped>
.ps-customizer-area {
  height: calc(100% - 83px);
}

::v-deep .p-0 .card-body {
  padding: 0 !important;
}

#result-component ::v-deep {
  .nav-wrapper-class {
    display: block;
    display: none; // FIXME - DISABLE
    position: sticky;
    top: 45px;
    z-index: 10;
    background: rgba(#f8f8f8, 0.8);
    backdrop-filter: blur(5px);
    margin: 0 0 1rem 0;
    border-radius: 8px;

    .nav-pills {
      margin: 0;
    }
  }
  .tab-title .nav-item  {
    width: 240px;
    background: #ffffff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    .nav-link.active {
      background: #DDF3FF;
      height: 100%;
      width: 100%;
      color: #005A8C;
      border: none;
    }
  }
}
</style>
