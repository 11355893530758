var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-modify-class-booking-add-flight-confirm".concat(_vm.isCheapest),
      "title": _vm.$t('reservation.historyActions.MODIFY_ADD_FLIGHT'),
      "title-class": "text-heading-3 text-airline font-weight-bolder",
      "centered": "",
      "no-close-on-backdrop": true,
      "size": "lg"
    },
    on: {
      "show": _vm.handleShowModal,
      "hide": _vm.handleHideModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "center rounded-pill width-100 mr-2",
          attrs: {
            "size": "md",
            "variant": "outline-secondary"
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.close')) + " ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-gradient mt-lg-25 border-0",
          attrs: {
            "disabled": !_vm.isConfirm,
            "pill": ""
          },
          on: {
            "click": function click($event) {
              return _vm.handleClick(_vm.selectedTrips);
            }
          }
        }, [_c('span', {
          staticClass: "align-middle"
        }, [_vm._v(" Thêm chuyến bay ")])])];
      }
    }])
  }, [_c('div', {
    staticClass: "text-heading-5"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.confirmAddFlight')) + " ")]), _c('div', {
    staticClass: "text-heading-4 text-success mt-2 mb-25 ml-1"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.newFlight')) + ": ")]), _c('IAmOverlay', {
    attrs: {
      "loading": _vm.isEmpty(_vm.tripsShow),
      "spinner-variant": 'info'
    }
  }, [_vm._l(_vm.tripsShow, function (itinerary, index) {
    return _c('b-card', {
      key: index,
      staticClass: "my-50 border-success shadow-lg mx-3",
      attrs: {
        "body-class": "py-1"
      }
    }, _vm._l(itinerary, function (segment, indexSegment) {
      return _c('div', {
        key: indexSegment
      }, [_c('div', {
        staticClass: "d-flex flex-wrap align-items-center"
      }, [_c('div', {
        staticClass: "font-weight-bolder"
      }, [_c('span', {
        staticClass: "mr-50 text-nowrap"
      }, [_vm._v(_vm._s(indexSegment + 1))]), _c('span', {
        staticClass: "mr-50 text-nowrap"
      }, [_vm._v(_vm._s(segment.airline) + _vm._s(segment.flightNumber))]), _c('span', {
        staticClass: "mr-50 text-nowrap"
      }, [_vm._v(_vm._s(segment.bookingClass.code))]), _c('span', {
        staticClass: "mr-50 text-nowrap"
      }, [_vm._v(_vm._s(_vm.convertISODateTime(segment.departureDate, segment.departureTimezone).dayMonth))]), _c('span', {
        staticClass: "mr-50 text-nowrap"
      }, [_vm._v(_vm._s(segment.departure) + _vm._s(segment.arrival))]), _c('span', {
        staticClass: "mr-50 text-nowrap"
      }, [_vm._v(_vm._s(_vm.statusSegment[index][indexSegment]))]), _c('span', [_vm._v(_vm._s(_vm.convertISODateTime(segment.departureDate, segment.departureTimezone).hourMin) + " " + _vm._s(_vm.convertISODateTime(segment.arrivalDate, segment.arrivalTimezone).hourMin))])])])]);
    }), 0);
  }), _c('div', {
    staticClass: "margin-checkbox-label d-flex-center mt-3"
  }, [_c('b-form-checkbox', {
    attrs: {
      "state": _vm.isConfirm === true ? true : false
    },
    model: {
      value: _vm.isConfirm,
      callback: function callback($$v) {
        _vm.isConfirm = $$v;
      },
      expression: "isConfirm"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('reservation.iAmConfirm')))])])], 1)], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }