<template>
  <div>
    <!-- SECTION modal-reservation-rebook-booking -->
    <b-modal
      id="modal-reservation-rebook-booking"
      title="Đặt lại vé"
      title-class="text-airline font-medium-4 font-weight-bolder"
      centered
      size="lg"
      no-close-on-backdrop
      @show="showHandle"
    >
      <template #modal-footer="{cancel}">
        <div class="d-flex justify-content-end w-100">
          <b-button
            size="md"
            variant="outline-secondary"
            class="center rounded-pill mr-2"
            @click="cancel()"
          >
            {{ $t('reservation.back') }}
          </b-button>

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="btn-gradient border-right-0"
            pill
            @click="submitRebook"
          >
            <div class="d-flex-center px-25">
              Xác nhận
            </div>
          </b-button>
        </div>
      </template>

      <b-card
        v-if="bookingData.bookingRequest"
        header-bg-variant="light-info"
        header-class="py-75"
        body-class="pb-75"
      >
        <template #header>
          <div class="w-100 d-flex justify-content-between font-weight-bolder text-airline">
            <span>Chi tiết vé:</span>
          </div>
        </template>

        <div class="mb-1">
          <p class="mb-25 mt-75 font-weight-bold">
            Thông tin hành trình:
          </p>
          <b-card
            v-for="(trip, tripIndex) in bookingData.bookingRequest.itineraries"
            :key="tripIndex"
            class="mb-75 pb-50 border rounded-lg"
            no-body
          >
            <code
              class="pl-50 mb-50 font-weight-bolder"
            >
              Hành trình: {{ bookingData.bookingRequest.itineraries.length > 1 ? `#${tripIndex + 1}` : '' }}
            </code>

            <div
              v-for="(segment, segmentIndex) in trip.segments"
              :key="segmentIndex"
              class="font-weight-bold ml-2"
            >
              <span class="font-weight-bolder">
                {{ `${resolveAirlineFlightNumber(segment.airline, segment.flightNumber)}` }}
                {{ segment.bookingClass }}
                {{ convertISODateTime(segment.departureTime).dayMonth }}
                {{ `${segment.departure}${segment.arrival}` }}
                {{ `${convertISODateTime(segment.departureTime).hourMin} ${convertISODateTime(segment.arrivalTime).hourMin}` }}
              </span>
            </div>

            <div
              v-if="bookingData.totalPrice && bookingData.totalPrice[tripIndex] && bookingData.totalPrice[tripIndex].totalAdult"
              class="font-medium-1 mt-50 ml-2 text-warning font-weight-bolder"
            >
              Giá vé: {{ formatCurrency(bookingData.totalPrice[tripIndex].totalAdult) }} / 1 người lớn.
            </div>

          </b-card>
        </div>

        <div class="mb-1">
          <span class="w-100 font-weight-bold mt-25">
            Hành khách:
          </span>
          <div class="font-weight-bolder border rounded-lg px-2 py-75">
            <p
              v-for="(p, pIndex) in bookingData.bookingRequest.paxLists"
              :key="pIndex"
              class="mb-50"
            >
              <span>
                {{ bookingData.bookingRequest.paxLists.length > 1 ? pIndex + 1 : '' }}
                {{ `${p.lastName}/${p.firstName} ${p.title ? p.title : ''} ${p.birthday ? convertISODateTime(p.birthday).date : ''}` }}
              </span>
            </p>
          </div>
        </div>
      </b-card>

      <b-card
        header-bg-variant="light-info"
        header-class="py-75"
        body-class="pb-75 mt-1"
      >
        <template #header>
          <div class="w-100 d-flex justify-content-between font-weight-bolder text-airline">
            <span>Chọn loại tìm kiếm hạng vé đặt lại vé:</span>
          </div>
        </template>
        <div class="d-flex justify-content-between flex-column">
          <b-form-group class="mb-0">
            <b-form-radio-group
              id="rebook-type-radios"
              v-model="dataAdd.rebookType"
              :options="typeReBookOptions"
              name="radios-stacked"
              stacked
            />
          </b-form-group>
        </div>
      </b-card>

      <BAlert
        variant="danger"
        show
        class="p-1"
      >
        <div>Lưu ý: Rebook vé trong tình trạng "Giữ chỗ" có thể bị hãng hủy do dupe code.</div>
        <div>Mỗi vé chỉ được rebook 1 lần.</div>
      </BAlert>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BCard,
  BButton,
  BFormGroup,
  BFormRadioGroup, BAlert,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import cloneDeep from 'lodash/cloneDeep'

import { formatCurrency, convertISODateTime, resolveAirlineFlightNumber } from '@/@core/utils/filter'
import { useRouter } from '@/@core/utils/utils'

import formValidation from '@core/comp-functions/forms/form-validation'

import useReservationHandle from '@reservation/useReservationHandle'

// import useToast from '@useToast'

export default {
  components: {
    BModal,
    BCard,
    BFormGroup,
    BButton,
    BFormRadioGroup,
    BAlert,
  },
  props: {
    bookingData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const {
      reBookReservation,
    } = useReservationHandle()
    // const { toastError } = useToast()
    const { router } = useRouter()

    const blankData = {
      rebookType: 'CURRENT_CLASS',
    }

    const typeReBookOptions = [
      {
        text: 'Rebook hạng vé hiện tại.',
        value: 'CURRENT_CLASS',
      },
      {
        text: 'Rebook với hạng vé THẤP hơn hoặc BẰNG hạng vé hiện tại (từ thấp đến cao).',
        value: 'NOT_EXCEED_CURRENT',
      },
      {
        text: 'Rebook với TẤT CẢ các hạng vé có sẵn (từ thấp đến cao).',
        value: 'EVERY_CLASS',
      },
    ]

    const dataAdd = ref(cloneDeep(blankData))

    const resetData = () => {
      dataAdd.value = cloneDeep(blankData)
    }

    const { refFormObserver, getValidationState } = formValidation(
      resetData,
    )

    function submitRebook() {
      const payload = {
        rebookType: dataAdd.value.rebookType,
      }

      const titleMsg = () => this.$createElement('div', {
        domProps: { innerHTML: 'Xác nhận đặt lại vé?' },
      })

      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: 'Đặt lại vé',
          cancelTitle: this.$t('reservation.back'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$bvModal.show('modal-api-loading')

            reBookReservation(props.bookingData.id, payload)
              .then(res => {
                emit('refetch-data') // reload list reservation
                const newId = res?.resBooking?.bookingId
                this.$bvModal.hide('modal-reservation-rebook-booking')
                router.push({ name: 'apps-reservations-modify', params: { id: newId } })
              })
              .catch(error => {
                console.error({ error })
              })
              .finally(() => {
                this.$bvModal.hide('modal-api-loading')
              })
          }
        })
    }

    function showHandle() { // for do something when open modal
      resetData()
    }

    return {
      dataAdd,
      showHandle,
      formatCurrency,
      submitRebook,
      convertISODateTime,
      typeReBookOptions,

      refFormObserver,
      getValidationState,
      resolveAirlineFlightNumber,
    }
  },
}
</script>

<style lang="scss" scoped>
#rebook-type-radios::v-deep {
  .custom-control.custom-radio {
    margin-bottom: 10px;
    .custom-control-label {
      margin-top: -2px !important;
      font-size: 18px !important;
    }}
}
</style>
