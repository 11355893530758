<template>
  <div>
    <!-- SECTION modal-modify-view-eticket-details -->
    <b-modal
      id="modal-modify-view-eticket-details"
      centered
      ok-variant="primary"
      no-close-on-backdrop
      footer-class="d-flex-center py-50"
      header-bg-variant="light-info"
      header-class="py-25 pr-0"
      size="xl"
      @show="handleOpenModal"
      @hide="handleHideModal"
    >
      <!-- ANCHOR - Header -->
      <template #modal-header="{close}">
        <b-row class="justify-content-between align-items-center w-100 mx-0">
          <h4 class="text-airline font-medium-4 font-weight-bolder m-50">
            {{ $t('reservation.eticketInfo') }}
          </h4>

          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="flat-secondary"
            class="mr-50"
            @click="close()"
          >
            <feather-icon
              icon="XIcon"
              class="text-dark"
            />
          </b-button>
        </b-row>
      </template>

      <!-- ANCHOR - Footer -->
      <template #modal-footer="{close}">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          size="md"
          variant="outline-secondary"
          class="center rounded-pill px-2"
          @click="close()"
        >
          {{ $t('reservation.back') }}
        </b-button>

        <!-- :disabled="true" -->
        <b-button
          v-if="!isReadonly
            && eTicketData
            && eTicketData.platingCarrier !== 'QH'
            && !['VN1A', 'VN1A_MT'].includes(eTicketData?.source)
          "
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="btn-gradient mr-1"
          pill
          :disabled="!isOutTicket || isBookingOutSystem"
          @click="addEticketToBooking"
        >
          <div class="d-flex-center px-25">
            <feather-icon
              icon="PlusCircleIcon"
              class="mr-50"
            />
            {{ $t('reservation.addTicketToBooking') }}
          </div>
        </b-button>
      </template>

      <b-overlay
        :show="loading"
        rounded="sm"
        no-fade
        variant="white"
        spinner-variant="info"
        opacity="0.85"
        style="min-height: 200px;"
      >
        <!-- ANCHOR QH -->
        <div v-if="eTicketData?.ticketText && ['', 'QH'].includes(eTicketData?.platingCarrier)">
          <ActionsHistory
            v-show="true"
            :history-data="eTicketData.ticketText"
          />
        </div>

        <b-row v-else-if="eTicketData">
          <b-col cols="12">
            <div class="d-flex-center font-weight-bolder text-dark">
              <div class="mr-2">
                {{ $t('reservation.eTicket') }}: <span class="text-airline">{{ eTicketData?.ticket?.number || eTicketData?.ticket?.number }}</span>
              </div>
              <div>
                {{ $t('invoice.customerName') }}: <span class="text-airline">{{ `${eTicketData?.passenger?.lastName || eTicketData?.ticket?.lastName}/ ${eTicketData?.passenger?.firstName || eTicketData?.ticket?.firstName} ${eTicketData?.passenger?.title || ''}` }}</span>
              </div>
            </div>

            <div class="d-flex-center font-weight-bolder text-dark my-2">
              <div
                v-if="eTicketData?.ticket?.coupons?.length"
                class="mr-1 mr-xl-4"
              >
                Number of Coupons: <span class="text-airline">{{ eTicketData.ticket.coupons.length }}</span>
              </div>
              <div
                v-if="eTicketData?.reservation?.createdDate"
                class="mr-1 mr-xl-4"
              >
                Created: <span class="text-airline">{{ convertISODateTime(eTicketData.reservation.createdDate).date }}</span>
              </div>
              <div
                v-if="eTicketData?.issuedDate"
                class="mr-1 mr-xl-4"
              >
                Isued: <span class="text-airline">{{ convertISODateTime(eTicketData.issuedDate).date }}</span>
              </div>
              <div class="mr-1 mr-xl-4">
                PNR: <span class="text-airline">{{ eTicketData?.reservation?.pnrNumber || eTicketData?.pnr }}</span>
              </div>
              <div class="mr-1 mr-xl-4">
                Type: <span class="text-airline">{{ eTicketData?.ticket?.type || eTicketData?.ticket?.ticketType }}</span>
              </div>
              <div v-if="eTicketData?.ticket?.status">
                Status: <span class="text-airline">{{ eTicketData?.ticket?.status }}</span>
              </div>
            </div>
          </b-col>

          <!-- SECTION - Table -->
          <b-col
            v-if="eTicketData?.ticket?.coupons?.length"
            cols="12"
            class="my-2"
          >
            <b-table
              style="max-height: 45vh"
              sticky-header
              responsive
              show-empty
              bordered
              primary-key="id"
              class="position-relative"
              thead-class="text-center"
              tbody-class="text-center"
              :items="eTicketData.ticket.coupons"
              :fields="eTicketTableColumn"
              :empty-text="$t('noRecordFund')"
            >
              <!-- ANCHOR Table Header -->
              <template
                v-for="(column, index) in eTicketTableColumn"
                #[`head(${column.key})`]="data"
              >
                <div
                  :key="index"
                  class="text-dark"
                >
                  <span class="text-nowrap">
                    {{ data.label }}
                  </span>
                </div>
              </template>

              <!-- ANCHOR Column NBR -->
              <template #cell(NBR)="{item}">
                {{ item.couponNumber }}
              </template>

              <!-- ANCHOR Column CX -->
              <template #cell(CX)="{ item }">
                {{ item.marriageGroup }}
              </template>

              <!-- ANCHOR Column AL -->
              <template #cell(AL)="{ item }">
                {{ item.airline }}
              </template>

              <!-- ANCHOR Column FLT -->
              <template #cell(FLT)="{ item }">
                {{ item.flightNumber }}
              </template>

              <!-- ANCHOR Column CL -->
              <template #cell(CL)="{ item }">
                {{ item.bookingClass }}
              </template>

              <!-- ANCHOR Column DEP -->
              <template #cell(DEP)="{ item }">
                <div class="">
                  {{ convertISODateTime(item.departure.at, item.departure.timezone).dayMonth }}
                </div>
              </template>

              <!-- ANCHOR Column FROM -->
              <template #cell(FROM)="{ item }">
                {{ item.departure.code }}
              </template>

              <!-- ANCHOR Column TO -->
              <template #cell(TO)="{ item }">
                {{ item.arrival.code }}
              </template>

              <!-- ANCHOR Column TIME -->
              <template #cell(TIME)="{ item }">
                {{ convertISODateTime(item.departure.at, item.departure.timezone).time }}
              </template>

              <!-- ANCHOR Column bk_st -->
              <template #cell(bk_st)="{ item }">
                <!-- FIXME - check -->
                {{ item.segmentStatus }}
              </template>

              <!-- ANCHOR Column fare_basic -->
              <template #cell(fare_basic)="{ item }">
                {{ item.fareBasisCode }}
              </template>

              <!-- ANCHOR Column status -->
              <template #cell(status)="{ item }">
                {{ item.status }}
              </template>

              <!-- ANCHOR Column freq_flyer -->
              <template #cell(freq_flyer)>
                <span class="">...</span>
                <!-- FIXME - -->
              </template>

              <!-- ANCHOR Column bags -->
              <template #cell(bags)="{ item: { bagAllowance } }">
                {{ bagAllowance || 'NILL' }}
              </template>
            </b-table>
          </b-col>

          <!-- SECTION - Table VN1A-->
          <b-col
            v-else-if="eTicketData?.ticket?.segments?.length"
            cols="12"
            class="my-2"
          >
            <b-table
              style="max-height: 45vh"
              sticky-header
              responsive
              show-empty
              bordered
              primary-key="id"
              class="position-relative"
              thead-class="text-center"
              tbody-class="text-center"
              :items="eTicketData.ticket.segments"
              :fields="eTicketVN1ATableColumn"
              :empty-text="$t('noRecordFund')"
            >
              <!-- ANCHOR Table Header -->
              <template
                v-for="(column, index) in eTicketTableColumn"
                #[`head(${column.key})`]="data"
              >
                <div
                  :key="index"
                  class="text-dark"
                >
                  <span class="text-nowrap">
                    {{ data.label }}
                  </span>
                </div>
              </template>

              <!-- ANCHOR Column AL -->
              <template #cell(AL)="{ item }">
                {{ item.airline }}
              </template>

              <!-- ANCHOR Column FLT -->
              <template #cell(FLT)="{ item }">
                {{ item.flightNumber }}
              </template>

              <!-- ANCHOR Column CL -->
              <template #cell(CL)="{ item }">
                {{ item.bookingClass }}
              </template>

              <!-- ANCHOR Column DEP -->
              <template #cell(DEP)="{ item }">
                {{ convertISODateTime(item.departureDate).dayMonth }}
              </template>

              <!-- ANCHOR Column FROM -->
              <template #cell(FROM)="{ item }">
                {{ item.departure }}
              </template>

              <!-- ANCHOR Column TO -->
              <template #cell(TO)="{ item }">
                {{ item.arrival }}
              </template>

              <!-- ANCHOR Column TIME -->
              <template #cell(TIME)="{ item }">
                {{ convertISODateTime(item.departureDate).time }}
              </template>

              <!-- ANCHOR Column fare_basic -->
              <template #cell(fare_basic)="{ item }">
                {{ item.fareBasisCode }}
              </template>

              <!-- ANCHOR Column status -->
              <template #cell(status)="{ item }">
                {{ item.status }}
              </template>

              <!-- ANCHOR Column freq_flyer -->
              <template #cell(freq_flyer)>
                <span class="">...</span>
                <!-- FIXME - -->
              </template>

              <!-- ANCHOR Column bags -->
              <template #cell(bags)="{ item: { baggageAllowance } }">
                {{ baggageAllowance || 'NILL' }}
              </template>
            </b-table>
          </b-col>

          <b-col cols="12">
            <div class="text-dark font-weight-bolder">
              <div v-if="eTicketData.fareCalc">
                Fare Calc: <p class="text-airline">
                  {{ eTicketData.fareCalc }}
                </p>
              </div>
              <div v-if="eTicketData?.ticket?.remarks?.length">
                Endorsement:
                <p
                  v-for="(remarkItem, remarkIndex) in eTicketData.ticket.remarks"
                  :key="remarkIndex"
                  class="text-airline"
                >
                  {{ remarkItem.endorsement.text || '' }}
                </p>
              </div>
              <div v-if="eTicketData.formOfPayment">
                Form of Payment: <p class="text-airline">
                  {{ eTicketData.formOfPayment }}
                </p>
              </div>
              <div v-if="eTicketData.issuedBy">
                Isued: <p class="text-airline">
                  {{ eTicketData.issuedBy }} <span v-if="eTicketData.agent && eTicketData.agent.agentID">{{ eTicketData.agent.agentID || '' }}</span>
                </p>
              </div>

              <!-- ANCHOR - GIÁ -->
              <div
                :class="`
                ${['VN1A', 'VN1A_MT'].includes(eTicketData?.source) ? 'd-flex-center' : 'd-flex'}
                `"
              >
                <div class="mr-xl-5 mr-2">
                  Fare: <span class="text-airline">
                    {{ formatCurrency(eTicketData.basePrice || eTicketData?.ticket?.netPrice) }}
                  </span>
                </div>
                <div class="mr-xl-5 mr-2">
                  Tax: <span class="text-airline">
                    {{ formatCurrency(eTicketData.totalTax || eTicketData?.ticket?.tax) }}
                  </span>
                </div>
                <div class="mr-xl-5 mr-2">
                  Total: <span class="text-airline">
                    {{ formatCurrency(eTicketData.totalPrice || eTicketData?.ticket?.total) }}
                  </span>
                </div>
              </div>
            </div>
          </b-col>

          <b-col
            cols="12"
            class="mt-1"
          >
            <b-card
              v-if="eTicketData.history"
              header-bg-variant="light-info py-75 mb-0"
              class=""
              no-body
            >
              <template #header>
                <div class="d-flex justify-content-between w-100">
                  <h6 class="text-airline font-medium-3 m-0">
                    {{ $t(`reservation.history.title`) }}
                  </h6>
                  <div
                    class="cursor-pointer"
                    :class="{ 'rotate-180': showActionsHistory }"
                    @click="showActionsHistory = !showActionsHistory"
                  >
                    <feather-icon
                      icon="ChevronDownIcon"
                      size="24"
                    />
                  </div>
                </div>
              </template>

              <ActionsHistory
                v-show="showActionsHistory"
                id="eTicketData"
                :history-data="eTicketData.history"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row v-else>
          <b-alert variant="danger">
            {{ $t('reservation.errGetEticket') }}
          </b-alert>
        </b-row>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BAlert,
  BCard,
  BModal,
  BOverlay,
  BRow,
  BCol,
  BTable,
} from 'bootstrap-vue'
import {
  ref, toRefs,
} from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import isEmpty from 'lodash/isEmpty'

import { formatCurrency, convertISODateTime } from '@/@core/utils/filter'

import useReservationHandle from '@reservation/useReservationHandle'

import useToast from '@useToast'

import ActionsHistory from './history.vue'

export default {
  components: {
    BModal,
    BOverlay,
    BAlert,
    BTable,
    BRow,
    BCol,
    BCard,
    BButton,

    ActionsHistory,
  },
  directives: {
    Ripple,
  },
  props: {
    ticketData: {
      type: Object,
      default: () => {},
    },
    isBookingOutSystem: {
      type: Boolean,
      default: false,
    },
    isOutTicket: {
      type: Boolean,
      default: false,
    },
    currentArrTicketLength: {
      type: Number,
      default: 0,
    },
    ticketSource: {
      type: String,
      default: () => '',
    },
    isReadonly: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const {
      loading,
      getBookingData,
      fetchEticketByNumber,
      eTicketUpdates,
      // shortenEticket,
    } = useReservationHandle()
    const { toastSuccess, toastError } = useToast()
    const { ticketSource, ticketData, isReadonly } = toRefs(props)

    const eTicketData = ref(null)
    const showActionsHistory = ref(true)

    const showShortenEticket = ref(true)
    const loadingShortenEticket = ref(false)
    const shortenEticketData = ref(null)

    async function handleOpenModal() { // Open Modal
      if (!eTicketData.value || (eTicketData.value && eTicketData.value.ticket.number !== props.ticketData.number)) {
        eTicketData.value = null
        await fetchEticketByNumber({
          source: isReadonly.value ? ticketSource.value : getBookingData.value?.source,
          ticket: {
            number: ticketData.value?.number,
            type: ticketData.value?.ticketType,
          },
        }).then(res => {
          eTicketData.value = res
        }).catch(() => {
          this.$bvModal.hide('modal-modify-view-eticket-details')
        })

        // if (['1S', '1S_CTRL'].includes(getBookingData.value.source)) {
        //   loadingShortenEticket.value = true
        //   await shortenEticket({
        //     source: isReadonly.value ? ticketSource.value : getBookingData.value?.source,
        //     ticketNumber: ticketData.value?.number,
        //   }).then(res => {
        //     shortenEticketData.value = res
        //   }).catch(() => {
        //     toastError({
        //       title: 'messagesList.error',
        //       content: 'Có lỗi xảy ra khi lấy thông tin số vé rút gọn, vui lòng kiểm tra va thử lại!',
        //     })
        //   }).finally(() => {
        //     loadingShortenEticket.value = false
        //   })
        // }
      }
    }

    function handleHideModal() {
      emit('update:ticketData', null)
    }

    function addEticketToBooking(bvModalEvent) {
      bvModalEvent.preventDefault()

      const payload = [
        {
          id: String(Number(props.currentArrTicketLength) + 1), // FIXME - thiếu id trả về từ get-tkt
          operation: 'ADD',
          ticket: {
            number: eTicketData.value.ticket.number,
            type: eTicketData.value.ticket.type,
          },
        },
      ]

      const titleMsg = () => this.$createElement('div', {
        domProps: { innerHTML: '<p> Xác nhận thêm số vé đã chọn? </p>' },
      })

      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: 'Thêm số vé',
          cancelTitle: this.$t('reservation.back'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$bvModal.show('modal-api-loading')

            eTicketUpdates(getBookingData.value, payload)
              .then(() => {
                this.$bvModal.hide('modal-modify-view-eticket-details')

                toastSuccess({
                  title: 'messagesList.success',
                  content: 'Thêm số vé thành công!',
                })
              })
              .catch(() => {
                toastError({
                  title: 'messagesList.error',
                  content: 'Có lỗi xảy ra khi thêm số vé, vui lòng kiểm tra lại!',
                })
              })
              .finally(() => {
                this.$bvModal.hide('modal-api-loading')
              })
          }
        })
    }

    const eTicketTableColumn = [
      // { label: 'checkbox', key: 'checkbox' },
      { label: 'NBR', key: 'NBR' },
      { label: 'CX', key: 'CX' },
      { label: 'AL', key: 'AL' },
      { label: 'FLT', key: 'FLT' },
      { label: 'CL', key: 'CL' },
      { label: 'DEP', key: 'DEP' },
      { label: 'FROM', key: 'FROM' },
      { label: 'TO', key: 'TO' },
      { label: 'TIME', key: 'TIME' },
      { label: 'BK ST', key: 'bk_st' },
      { label: 'fare_basic', key: 'fare_basic' },
      { label: 'status', key: 'status' },
      { label: 'freq_flyer', key: 'freq_flyer' },
      { label: 'bags', key: 'bags' },
    ]

    const eTicketVN1ATableColumn = [
      { label: 'AL', key: 'AL' },
      { label: 'FLT', key: 'FLT' },
      { label: 'CL', key: 'CL' },
      { label: 'DEP', key: 'DEP' },
      { label: 'FROM', key: 'FROM' },
      { label: 'TO', key: 'TO' },
      { label: 'TIME', key: 'TIME' },
      { label: 'fare_basic', key: 'fare_basic' },
      { label: 'bags', key: 'bags' },
    ]

    return {
      loading,
      eTicketData,
      eTicketTableColumn,
      eTicketVN1ATableColumn,
      getBookingData,
      showActionsHistory,

      isEmpty,
      formatCurrency,
      convertISODateTime,
      handleOpenModal,
      handleHideModal,
      addEticketToBooking,

      loadingShortenEticket,
      showShortenEticket,
      shortenEticketData,
    }
  },
}
</script>
