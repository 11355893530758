<template>
  <!-- SECTION modal-modify-ancillary-pay-options -->
  <b-modal
    id="modal-modify-ancillary-pay-options"
    centered
    no-close-on-backdrop
    size="lg"
    @show="handleShowModal"
  >
    <template #modal-header="{close}">
      <div
        class="w-100 d-flex justify-content-between align-items-center"
      >
        <h3 class="mb-0">
          Thanh toán
        </h3>
        <b-button
          variant="flat-secondary"
          class="px-1 py-50"
          @click="close"
        >
          <feather-icon
            icon="XIcon"
            size="24"
          />
        </b-button>
      </div>
    </template>

    <AncillaryPayOptions
      v-if="bookingData && ['1S', '1S_CTRL', 'VJ'].includes(bookingData.source)"
      :reservations-data="bookingData"
      :is-pay-now.sync="isPay"
      :has-paid-trip="hasPaidTrip"
      :place="place"
    />

    <div
      v-if="bookingData && !['1S', '1S_CTRL', 'VJ'].includes(bookingData.source)"
    >
      Xác nhận thêm dịch vụ?
    </div>

    <template #modal-footer="{close}">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="outline-secondary"
        class="px-1 px-md-2"
        pill
        @click="close()"
      >
        {{ $t('reservation.back') }}
      </b-button>

      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        :variant="['VU', 'QH'].includes(bookingData.source) ? '' : isPay ? 'success' : 'warning'"
        :class="['VU', 'QH'].includes(bookingData.source) ? 'btn-gradient' : ''"
        pill
        @click="handleConfirm"
      >
        <span>
          {{ ['VU', 'QH'].includes(bookingData.source) ? 'Xác nhận' : isPay ? 'THANH TOÁN NGAY' : 'THANH TOÁN SAU' }}
        </span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal,
  BButton,
} from 'bootstrap-vue'
import {
  // eslint-disable-next-line no-unused-vars
  ref, toRefs, watch,
} from '@vue/composition-api'

import AncillaryPayOptions from './CardAncillaryPayOptions.vue'

export default {
  components: {
    BModal,
    BButton,
    AncillaryPayOptions,
  },
  props: {
    reservationsData: {
      type: Object,
      default: () => {},
    },
    isPayNow: {
      type: Boolean,
      default: () => false,
    },
    hasPaidTrip: {
      type: Boolean,
      default: () => false,
    },
    place: {
      type: String,
      default: () => '',
    },

  },
  setup(props, { emit }) {
    const isPay = ref(false)
    const bookingData = toRefs(props).reservationsData

    watch(isPay, val => {
      emit('update:is-pay-now', val)
    }, { immediate: true })

    function handleShowModal() {
      // reset với case 1S có hành trình chưa thanh toán và đã thanh toán
      let isPayNow = false
      isPay.value = false

      if ((['VJ'].includes(bookingData.value.source) && bookingData.value.totalAmountToBePaid <= 0) || (['AK'].includes(bookingData.value.source))) {
        isPay.value = true
        isPayNow = true
      }

      emit('update:is-pay-now', isPayNow)
    }

    function handleConfirm() {
      emit('submit')
    }

    return {
      bookingData,
      handleConfirm,
      handleShowModal,
      isPay,
    }
  },
}
</script>

  <style lang="scss" scoped>
  #modal-delete-osi ::v-deep {
    .custom-checkbox .custom-control-label,
    .custom-radio .custom-control-label {
      margin-top: 0;
    }
  }
  </style>
