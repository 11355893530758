<template>
  <BModal
    :id="`modal-command-response-${functionName}`"
    :title="titleModal"
    title-class="text-airline font-medium-4 font-weight-bolder"
    body-class="px-75"
    centered
    size="lg"
  >
    <template #modal-footer="{ close }">
      <b-button
        variant="flat-dark"
        class="rounded-pill px-2"
        @click="close"
      >
        {{ $t('reservation.close') }}
      </b-button>
    </template>

    <IAmOverlay :loading="!response">
      <div
        v-if="response"
        class="position-relative"
      >
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="flat-secondary"
          class="position-absolute p-75 rounded-circle m-25"
          style="top: 0; right: 10px; z-index: 9999;"
          :disabled="disabledCopy"
          @click="handleCopyText"
        >
          <feather-icon
            v-b-tooltip.hover.window
            icon="CopyIcon"
            size="21"
            class="text-dark"
            :title="$t('copy')"
          />
        </b-button>

        <template v-if="!isEmpty(response.booking)">
          <b-form-textarea
            :value="response.booking.join('\n')"
            class="font-weight-bolder text-uppercase"
            rows="16"
            max-rows="40"
            style="font-size: 16px; line-height: 24px; background-color:#ffffc0; color:#000; font-family:'Calibri', sans-serif; height: 60vh; overflow: auto;"
          />
        </template>
      </div>
    </IAmOverlay>
  </BModal>
</template>

<script>
import { ref, toRefs } from '@vue/composition-api'
import { useClipboard } from '@vueuse/core'
import { BModal, BButton, BFormTextarea } from 'bootstrap-vue'
import isEmpty from 'lodash/isEmpty'

export default {
  components: {
    BModal,
    BButton,
    BFormTextarea,
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
  },
  props: {
    functionName: {
      type: String,
      required: true,
    },
    titleModal: {
      type: String,
      default: 'Kết quả',
    },
    response: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const response = toRefs(props).response
    const { copy } = useClipboard()
    const disabledCopy = ref(false)
    function handleCopyText() {
      if (!response.value || (response.value && isEmpty(response.value.booking))) return

      disabledCopy.value = true

      setTimeout(() => {
        disabledCopy.value = false
      }, 1000)

      const textValue = response.value.booking.join('\n')
      copy(textValue)
        .then(() => {
          this.$root.toastSuccess('reservation.sms.copySuccess')
        })
        .catch(() => {
          this.$root.toastError('reservation.sms.copyFail')
        })
    }
    return {
      handleCopyText,
      disabledCopy,
      isEmpty,
    }
  },
}
</script>
