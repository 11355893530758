var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "my-1 mx-75"
  }, [_c('b-form-textarea', {
    staticClass: "font-weight-bolder text-uppercase",
    staticStyle: {
      "font-size": "16px",
      "line-height": "24px",
      "background-color": "#ffffc0",
      "color": "#000",
      "font-family": "'Calibri', sans-serif"
    },
    attrs: {
      "rows": "10",
      "max-rows": "40"
    },
    model: {
      value: _vm.historyDataText,
      callback: function callback($$v) {
        _vm.historyDataText = $$v;
      },
      expression: "historyDataText"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }