<template>
  <div>
    <!-- SECTION modal-reservation-booking-export-excel -->
    <b-modal
      id="modal-reservation-booking-export-excel"
      title="Xuất Excel danh sách đặt chỗ"
      title-class="text-airline font-medium-4 font-weight-bolder"
      centered
      body-class="px-75"
      size="lg"
      no-close-on-backdrop
      @show="formatFilter"
    >
      <template #modal-footer="{cancel}">
        <b-button
          variant="outline-secondary"
          class="center rounded-pill"
          @click="cancel()"
        >
          {{ $t('reservation.back') }}
        </b-button>

        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="btn-gradient border-right-0"
          pill
          @click="handleExport"
        >
          <div class="d-flex-center px-25">
            Xác nhận
          </div>
        </b-button>
      </template>

      <b-card body-class="px-75 d-flex flex-column my-auto pb-1">
        <b-row class="mx-0 mt-75 justify-content-center align-items-center">
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group class="w-100">
              <label class="font-weight-bold">{{ $t('reservation.bookDate') }}</label>
              <b-input-group
                id="range-date"
                class=""
              >
                <flat-pickr
                  v-model="rangeDate"
                  class="form-control"
                  :config="{
                    mode: 'range',
                    dateFormat: 'Y-m-d',
                    altFormat: 'd-m-Y',
                    altInput: true,
                    maxDate: today,
                    locale: this.$i18n.locale === 'vi' ? Vietnamese : null,
                    disableMobile: true,
                  }"
                  placeholder="Chọn thời gian"
                />
                <b-input-group-append is-text>
                  <feather-icon icon="CalendarIcon" />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="4"
            class=""
          >
            <b-form-group>
              <label class="font-weight-bold">{{ $t('reservation.airline') }}</label>
              <v-select
                v-model="airline"
                placeholder="Lựa chọn"
                :reduce="airline => airline.value"
                label="text"
                :options="[
                  {
                    text:'Vietravel Airlines',
                    value: 'VU'
                  },
                  {
                    text:'Vietjet Air',
                    value: 'VJ'
                  },
                  {
                    text:'Vietnam Airlines',
                    value: 'VN'
                  },
                  {
                    text:'Bamboo Airways',
                    value: 'QH'
                  }
                ]"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="4"
            class=""
          >
            <b-form-group>
              <label class="font-weight-bold">{{ $t('reservation.status') }}</label>
              <v-select
                v-model="status"
                placeholder="Lựa chọn"
                :options="['ALL', 'HOLD', 'PAID', 'PARTIALLY_PAID', 'CANCEL']"
              >
                <template #option="data">
                  {{ $te(`reservation.${data.label}`)? $t(`reservation.${data.label}`) : data.label }}
                </template>

                <template #selected-option="data">
                  {{ $te(`reservation.${data.label}`)? $t(`reservation.${data.label}`) : data.label }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>

      <b-card
        class="mx-2 text-center"
        no-body
      >
        <b-alert
          variant="warning"
          class="mb-0 py-50"
          show
        >
          Mặc định sẽ xuất Excel theo ngày hiện tại, tất cả các hãng và trạng thái vé
        </b-alert>
      </b-card>
    </b-modal>
    <!-- !SECTION -->

    <!-- <b-modal
        id="modal-modify-quick-add-flight-confirm"
        title="Xác nhận thông tin chuyến bay"
        title-class="text-airline font-medium-4 font-weight-bolder"
        centered
        lazy
        size="md"
        no-close-on-backdrop
        @hide="hideModalConfirm"
        @show="showModalConfirm"
      >
        <template #modal-footer="{cancel}">
          <b-button
            variant="outline-secondary"
            class="center rounded-pill"
            @click="cancel()"
          >
            {{ $t('reservation.back') }}
          </b-button>

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="btn-gradient border-right-0"
            pill
            :disabled="!isConfirm"
            @click="handleClick"
          >
            <div class="d-flex-center px-25">
              Thêm chuyến bay
            </div>
          </b-button>
        </template>

        <b-card>
          <div
            class="font-weight-bolder text-center"
          >
            <span>{{ dataConfirm }}</span>
          </div>
        </b-card>

        <IAmConfirm
          class="mt-2"
          :is-confirm.sync="isConfirm"
        />
      </b-modal> -->
  </div>
</template>

<script>
import {
  BModal,
  BCard,
  BRow,
  BCol,
  BAlert,
  BButton,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import { computed, ref, watch } from '@vue/composition-api'
import { isEmpty } from 'lodash'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Vietnamese } from 'flatpickr/dist/l10n/vn'

import VueI18n from '@/libs/i18n'
import { formatCurrency, convertISODateTime } from '@/@core/utils/filter'

import useReservationHandle from '@reservation/useReservationHandle'

import useToast from '@useToast'

export default {
  components: {
    BModal,
    BCard,
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
    BAlert,
    BRow,
    BCol,
    BButton,
    BInputGroupAppend,
    BInputGroup,
    flatPickr,
    vSelect,
  },
  methods: {
    getMaxDate() {
      const currentDate = new Date()
      const maxDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 3, currentDate.getDate())
      return maxDate
    },
  },
  setup() {
    const {
      exportBooking,
    } = useReservationHandle()

    const rangeDate = ref(null)
    const isConfirm = ref(false)

    const startDate = ref(null)
    const endDate = ref(null)
    const status = ref(null)
    const airline = ref(null)

    const locale = computed(() => VueI18n.locale)

    const formatFilter = () => {
      rangeDate.value = null
      startDate.value = null
      endDate.value = null
      status.value = null
      airline.value = null
    }

    // eslint-disable-next-line no-unused-vars
    const { toastError, toastSuccess } = useToast()

    function handleExport() {
      const timeZoneOffset = new Date().getTimezoneOffset()
      const today = convertISODateTime(new Date()).dateFilter
      const language = this.$i18n.locale

      const payload = {
        startDate: startDate.value ? startDate.value : today,
        endDate: endDate.value ? endDate.value : today,
        status: status.value ? status.value : null,
        airline: airline.value ? airline.value : null,
        timeZoneOffset,
        language,
      }

      const titleMsg = () => this.$createElement('div', {
        domProps: { innerHTML: this.$t('Xác nhận thông tin và xuất file Excel đặt vé?') },
      })
      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('export'),
          cancelTitle: this.$t('reservation.back'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$bvModal.show('modal-api-loading')

            exportBooking(payload)
              .then(() => {
                //
              })
              .catch(() => {
                //
              })
              .finally(() => {
                this.$bvModal.hide('modal-api-loading')
              })
          }
        })
    }

    watch(rangeDate, val => {
      if (val) {
        const sepSign = locale.value === 'vi' ? ' đến ' : ' to '
        const dateRange = val.split(sepSign)
        if (dateRange.length) {
          startDate.value = dateRange[0]
          endDate.value = dateRange[dateRange.length - 1]
        }
      }
    })
    const today = convertISODateTime(new Date()).dateFilter
    return {
      today,
      isEmpty,
      isConfirm,
      rangeDate,
      Vietnamese,
      airline,
      status,
      formatFilter,

      formatCurrency,
      convertISODateTime,
      handleExport,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
#range-date ::v-deep {
  input.form-control.input {
    border-radius: 0.357rem 0 0 0.357rem;
    border-right: none;
  }
}
.no_wrap_input .input-group::v-deep {
  flex-wrap: nowrap !important;
  .autosuggest__results {
    min-width: 166px; // min-xl-wrap
  }
}
</style>
