var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "id-modal-reservation-send-email",
      "title": _vm.$t('reservation.sendEmail.title'),
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "body-class": "p-1",
      "centered": "",
      "no-close-on-backdrop": "",
      "size": "lg"
    },
    on: {
      "show": _vm.showModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(234, 84, 85, 0.15)',
            expression: "'rgba(234, 84, 85, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          attrs: {
            "variant": "outline-danger",
            "pill": ""
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.back')) + " ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-gradient px-1 px-md-2",
          attrs: {
            "pill": ""
          },
          on: {
            "click": function click($event) {
              return _vm.handleSendEmail();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.historyActions.SEND_EMAIL')) + " ")])];
      }
    }])
  }, [_c('div', {
    staticClass: "mb-1"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.sendEmail.chooseLanguage')) + " ")]), _c('v-select', {
    attrs: {
      "clearable": false,
      "reduce": function reduce(l) {
        return l.value;
      },
      "label": "key",
      "options": _vm.languageOptions
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(_vm.$te("reservation.languageCode.".concat(data.key)) ? _vm.$t("reservation.languageCode.".concat(data.key)) : data.key) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(_vm.$te("reservation.languageCode.".concat(data.key)) ? _vm.$t("reservation.languageCode.".concat(data.key)) : data.key) + " ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.language,
      callback: function callback($$v) {
        _vm.language = $$v;
      },
      expression: "language"
    }
  }), _vm.getBookingData && ['VN1A', 'VN1A_MT'].includes(_vm.getBookingData.source) ? _c('div', {
    staticClass: "mt-1"
  }, [['PAID'].includes(_vm.getBookingData.status) ? _c('div', {
    staticClass: "d-flex mb-50"
  }, [_c('div', {
    staticClass: "text-nowrap"
  }, [_vm._v(" Gửi kèm số vé ")]), _c('BFormCheckbox', {
    staticClass: "ml-75",
    attrs: {
      "name": "custom-email",
      "switch": ""
    },
    model: {
      value: _vm.isGetETicket,
      callback: function callback($$v) {
        _vm.isGetETicket = $$v;
      },
      expression: "isGetETicket"
    }
  })], 1) : _vm._e(), _vm.isGetETicket ? _c('div', {
    staticClass: "text-warning mb-1"
  }, [_vm._v(" Lưu ý: Gửi kèm số vé sẽ gửi về mail mặc định, không thể thay đổi ")]) : _vm._e(), _c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "text-nowrap"
  }, [_vm._v(" Nhập email (tuỳ chọn) ")]), _c('BFormCheckbox', {
    staticClass: "ml-75",
    attrs: {
      "name": "custom-email",
      "switch": ""
    },
    model: {
      value: _vm.isCustomEmail,
      callback: function callback($$v) {
        _vm.isCustomEmail = $$v;
      },
      expression: "isCustomEmail"
    }
  })], 1), _vm.isCustomEmail ? _c('BFormInput', {
    staticClass: "mt-75",
    attrs: {
      "placeholder": "Nhập vào email"
    },
    model: {
      value: _vm.email,
      callback: function callback($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  }) : _vm._e()], 1) : _vm._e(), _vm.getBookingData && ['VJ'].includes(_vm.getBookingData.source) ? _c('div', {
    staticClass: "mt-1"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "text-nowrap"
  }, [_vm._v(" Ẩn giá vé khi gửi email (Vietjet Air) ")]), _c('BFormCheckbox', {
    staticClass: "ml-75",
    attrs: {
      "name": "isHiddenFare",
      "switch": ""
    },
    model: {
      value: _vm.isHiddenFare,
      callback: function callback($$v) {
        _vm.isHiddenFare = $$v;
      },
      expression: "isHiddenFare"
    }
  })], 1)]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }