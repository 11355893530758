<template>
  <div class="my-1 mx-75">
    <b-form-textarea
      v-model="historyDataText"
      class="font-weight-bolder text-uppercase"
      rows="10"
      max-rows="40"
      style="font-size: 16px; line-height: 24px; background-color:#ffffc0; color:#000; font-family:'Calibri', sans-serif;"
    />
  </div>
</template>

<script>
import {
  BFormTextarea,
} from 'bootstrap-vue'
import { computed, toRefs } from '@vue/composition-api'

import {
  dateTime, formatCurrency,
} from '@core/utils/filter'

export default {
  components: {
    BFormTextarea,
  },

  props: {
    historyData: {
      type: [Array, String],
      required: true,
    },
  },
  setup(props) {
    const { historyData } = toRefs(props)
    const historyDataText = computed(() => {
      if (Array.isArray(historyData.value)) {
        return historyData.value.join('\n')
      }
      return historyData.value
    })

    return {
      historyDataText,
      dateTime,
      formatCurrency,
    }
  },
}
</script>
