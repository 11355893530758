var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "result-component"
    }
  }, [_vm.getLoading ? _c('div', {
    staticClass: "text-center mt-3",
    staticStyle: {
      "min-height": "100px"
    }
  }, [_c('b-spinner', {
    attrs: {
      "variant": "info"
    }
  }), _c('p', {
    staticClass: "text-info"
  }, [_vm._v(" Đang tìm kiếm chuyến bay... ")])], 1) : !_vm.isEmpty(_vm.resultSearchFlight) ? _c('div', [_c('HeaderTabFlight'), _c('b-tabs', {
    attrs: {
      "pills": "",
      "nav-class": "tab-title",
      "nav-wrapper-class": "nav-wrapper-class"
    },
    model: {
      value: _vm.tabIndex,
      callback: function callback($$v) {
        _vm.tabIndex = $$v;
      },
      expression: "tabIndex"
    }
  }, _vm._l(_vm.resultSearchFlight, function (itinerary, indexItinerary) {
    return _c('b-tab', {
      key: indexItinerary
    }, [_c('ItineraryTab', {
      attrs: {
        "itinerary": itinerary,
        "index-itinerary": indexItinerary,
        "is-add-flight": _vm.isAddFlight,
        "selected-trip": _vm.selectedTrips[indexItinerary]
      },
      on: {
        "update:selectedTrip": function updateSelectedTrip($event) {
          return _vm.$set(_vm.selectedTrips, indexItinerary, $event);
        },
        "update:selected-trip": function updateSelectedTrip($event) {
          return _vm.$set(_vm.selectedTrips, indexItinerary, $event);
        }
      }
    })], 1);
  }), 1)], 1) : _vm._e(), !_vm.getLoading && _vm.isAddFlight && !_vm.isEmpty(_vm.resultSearchFlight) ? _c('div', {
    staticClass: "w-100 d-flex-center my-75"
  }, [_c('b-button', {
    staticClass: "px-2 py-50 mr-1 rounded-pill d-flex-center",
    attrs: {
      "variant": "outline-info"
    },
    on: {
      "click": _vm.handleRefreshResultSearch
    }
  }, [_c('feather-icon', {
    staticClass: "mr-1",
    attrs: {
      "icon": "RefreshCwIcon",
      "size": "18"
    }
  }), _vm._v(" Làm mới ")], 1)], 1) : _vm._e(), _vm.isAddFlight ? _c('AddFlightFooter', {
    attrs: {
      "booking-data": _vm.bookingData,
      "selected-trips": _vm.selectedTrips
    },
    on: {
      "update:selectedTrips": function updateSelectedTrips($event) {
        _vm.selectedTrips = $event;
      },
      "update:selected-trips": function updateSelectedTrips($event) {
        _vm.selectedTrips = $event;
      }
    }
  }) : _vm._e(), !_vm.isAddFlight ? _c('ChangeFlightFooter', {
    attrs: {
      "booking-data": _vm.bookingData,
      "selected-trips": _vm.selectedTrips
    },
    on: {
      "update:selectedTrips": function updateSelectedTrips($event) {
        _vm.selectedTrips = $event;
      },
      "update:selected-trips": function updateSelectedTrips($event) {
        _vm.selectedTrips = $event;
      }
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }